import {useState} from 'react';
import CustomToolbar from "./toolbar/CustomToolbar";
import Sidebar from "./sidebar/Sidebar";
import {Box} from "@mui/material";


const Navigation = ({children}) => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <Box display='flex'>
            <CustomToolbar handleSidebarToggle={handleSidebarToggle}/>
            <Sidebar sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle}/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, p: 3, mt: {
                        xs: '57px',
                        sm: '80px'
                    }, width: {xs: '100%', sm: `calc(100% - 240px)`}
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

export default Navigation;
