import React from "react"
import {Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import PropTypes from "prop-types";
import {theme} from "../../../theme/theme";
import CloseIcon from "@mui/icons-material/Close";

const GenericDialog = (props) => {
    return (
        <Dialog
            fullWidth
            maxWidth={!props.loading ? props.width : 'sm'}
            open={props.open}
            onClose={props.handleDialogClose}
        >
            <DialogTitle>
                {props.title}

                {props.handleDialogCloseButton && <IconButton
                    aria-label="close"
                    onClick={props.handleDialogCloseButton}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                }
            </DialogTitle>

            {!props.loading ? <>
                    <DialogContent>{props.content}</DialogContent>

                    {props.actions && <DialogActions
                        sx={{
                            margin: "0 0 1em 0",
                            padding: theme.spacing(2),
                        }}>
                        {props.actions}
                    </DialogActions>
                    }
                </>
                :
                <Box textAlign='center' p={5}>
                    <CircularProgress size={64}/>
                </Box>
            }
        </Dialog>
    )
};

GenericDialog.propTypes = {
    width: PropTypes.oneOf(["xl", "md", "sm", "xs", "lg", false]),
    open: PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    handleDialogCloseButton: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
    actions: PropTypes.element
};

GenericDialog.defaultProps = {
    width: 'lg'
}

export default GenericDialog;
