import {Suspense} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";

const RouteWrapper = ({component: Component, layout: Layout, canSeeRoute, redirectTo}) => {
    let location = useLocation();

    const baseComponent = (
        <Layout>
            <Suspense fallback={
                <Backdrop open={true}>
                    <CircularProgress color="primary"/>
                </Backdrop>
            }>
                <Component/>
            </Suspense>
        </Layout>
    )
    return (
        canSeeRoute ? baseComponent : <Navigate to={redirectTo} state={{from: location}}/>
    )
}

export default RouteWrapper;
