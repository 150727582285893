import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import {ListItemButton, ListItemIcon, Typography, useTheme} from "@mui/material";

const DrawerListItem = (props) => {
    const theme = useTheme()

    return (
        <ListItemButton
            onClick={props.onClick}
            sx={{
                padding: "0"
            }}>
            <NavLink
                to={props.to}
                activeStyle={{backgroundColor: "#b14536"}}
                style={{
                    width: "100%",
                    display: "inline-flex",
                    padding: "16px",
                    textDecoration: "none"
                }}>
                <ListItemIcon color='white'>
                    {props.icon}
                </ListItemIcon>
                <Typography variant={props.fontStyle} sx={{
                    color: theme.palette.common.white,
                    alignSelf: "center"
                }}>
                    {props.text}
                </Typography>
            </NavLink>
        </ListItemButton>
    )
}

DrawerListItem.propTypes = {
    to: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

DrawerListItem.defaultProps = {
    onClick: () => {
    }
}

export default DrawerListItem;
