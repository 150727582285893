import PropTypes from "prop-types";
import ToolbarInfoBoxes from "./toolbar-info-boxes/ToolbarInfoBoxes";
import {AppBar, IconButton, Toolbar, Typography, useTheme} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";
import CustomersDialog from "./customers-dialog/CustomersDialog";
import KeycloakService from "../../../service/KeycloakService";
import {RESOURCE_ACCESS_PORTALE_GA, ROLE_OPERATOR} from "../../../util/constants/KeycloakConstants";

const CustomToolbar = (props) => {
    const isOperator = KeycloakService.hasResourceRole([ROLE_OPERATOR], RESOURCE_ACCESS_PORTALE_GA)
    const [customersDialogOpen, setCustomersDialogOpen] = useState(false)
    const theme = useTheme()
    const isMasterFather = KeycloakService.getInstance.tokenParsed.userType === "REPRESENTATIVE_USER";

    const drawerWidth = 240

    const handleOpenCustomersDialog = () => {
        setCustomersDialogOpen(true);
    }

    return (
        <AppBar position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {sm: `${drawerWidth}px`},
                    bgcolor: theme.palette.common.white
                }}>
            <Toolbar sx={{
                minHeight: {sm: '80px'}
            }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={props.handleSidebarToggle}
                    sx={{mr: 2, display: {sm: 'none'}}}
                >
                    <MenuIcon/>
                </IconButton>

                <Typography variant="h6" noWrap sx={{flexGrow: 1}} color={theme.palette.common.black}>
                    Gestione Tipologia Sede
                </Typography>

                <ToolbarInfoBoxes handleSetDialogOpen={handleOpenCustomersDialog}/>
            </Toolbar>

            {(customersDialogOpen && (isOperator || isMasterFather)) && <CustomersDialog
                handleDialogClose={() => setCustomersDialogOpen(false)}
                open={customersDialogOpen}/>
            }

        </AppBar>
    )
}

CustomToolbar.propTypes = {
    handleSidebarToggle: PropTypes.func.isRequired
}

export default CustomToolbar;
