import DrawerList from "./drawer-list/DrawerList";
import PropTypes from "prop-types";
import {Box, Drawer, useTheme} from "@mui/material";

const Sidebar = (props) => {
    const theme = useTheme()
    const container = props.window !== undefined ? () => window.document.body : undefined;
    const drawerWidth = 240;

    const drawerPaperStyles = {
        width: drawerWidth,
        background: 'linear-gradient(#fc6969, #f09718)',
        "&::MsOverflowStyle": "none",
        scrollbarWidth: "none",
        "&::WebkitScrollbar": {
            display: "none"
        }
    }

    return (
        <Box
            component="nav"
            sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
        >
            <Drawer
                id="drawer-paper"
                container={container}
                variant="temporary"
                open={props.sidebarOpen}
                onClose={props.handleSidebarToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': drawerPaperStyles,
                }}
            >
                <DrawerList/>
                <Box sx={theme.mixins.toolbar}/>
            </Drawer>


            <Drawer
                variant="permanent"
                sx={{
                    display: {xs: 'none', sm: 'block'},
                    '& .MuiDrawer-paper': drawerPaperStyles,
                }}
                open
            >
                <DrawerList/>
            </Drawer>
        </Box>
    )
}

Sidebar.propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    handleSidebarToggle: PropTypes.func.isRequired
}
export default Sidebar;
