import Keycloak from "keycloak-js";

const getKeycloakConfigFile = () => {
    const host = window.location.hostname
    if (host.includes('localhost') || host.includes('operator-pmtag-int.fastbee.systems'))
        return '/perfmon-keycloak-operator-int.json'
    else if (host.includes('pmtag-int.fastbee.systems'))
        return '/perfmon-keycloak-customer-int.json'
    else if (host.includes('operator-pmtag.fastweb.it'))
        return '/perfmon-keycloak-operator-prod.json'
    else if (host.includes('pmtag.fastweb.it'))
        return '/perfmon-keycloak-customer-prod.json'
    else
        return null
}

export const enableAuth = true

const _kc = new Keycloak(process.env.PUBLIC_URL + getKeycloakConfigFile());

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        promiseType: "native",
        onLoad: "check-sso",
        flow: "standard",
        checkLoginIframe: false,
        redirectUri: process.env.PUBLIC_URL
    })
        .then((authenticated) => {
            if (authenticated) {
                onAuthenticatedCallback();
            } else {
                doLogin();
            }
        })
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getTokenParsed = _kc.tokenParsed

const getInstance = _kc

const hasResourceRole = (roles, resource) => roles.some(role => _kc.hasResourceRole(role, resource))

const KeycloakService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    hasResourceRole,
    getTokenParsed,
    getInstance
};

export default KeycloakService;
