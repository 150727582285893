import {useCallback} from "react";
import {Divider, MenuItem, MenuList, Popover, Typography} from "@mui/material";

const ClickableDropdown = ({setAnchorEl, ...props}) => {
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl])

    const menuItemStyles = {
        padding: "1em"
    }

    const open = Boolean(props.anchorEl);

    return (
        <Popover
            open={open}
            anchorEl={props.anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuList id="menu-list-grow">
                {props.menuItems.map((item, idx) => {
                    if (item.text === "divider") {
                        return (<Divider key={"divider-" + idx}/>)
                    } else if (item.hideItem) {
                        return null
                    } else {
                        return (
                            <MenuItem key={"menu-item-" + idx}
                                      sx={menuItemStyles}
                                      onClick={() => {
                                          item.handleClick();
                                          handleClose();
                                      }}
                                      disabled={item.disabled}>
                                {item.icon ? item.icon : null}
                                <Typography marginRight={item.hasIcon ? '0.5em' : 0} variant="body2">
                                    {item.text}
                                </Typography>
                            </MenuItem>
                        )
                    }
                })}
            </MenuList>
        </Popover>
    )
}


export default ClickableDropdown;
