import React from "react";
import Container from "@mui/material/Container";

const ContainerLayout = ({children}) => {

    return <Container maxWidth="lg">
        {children}
    </Container>

}

export default ContainerLayout;