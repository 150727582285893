import {applyMiddleware, combineReducers, compose, legacy_createStore as createStore} from "redux";
import thunk from "redux-thunk";
import locationsReducer from "./locations/reducer";
import tagManagerReducer from "./tag-manager/reducer";
import customersReducer from "./customers/reducer";


const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
    locationsReducer,
    tagManagerReducer,
    customersReducer
})

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
