import * as actionTypes from "./actionTypes";
import CustomersApiClient from "../../api/customersApiClient";
import {getSortingObject} from "../../util/utils";

export const getClientsList = (page, rowsPerPage, sortBy, searchText, integrationId) => {
    const {sortField, sortOrder} = getSortingObject(sortBy)
    return (dispatch) => {
        dispatch({type: actionTypes.GET_CUSTOMERS_LOADING, value: true})
        CustomersApiClient.fetchClientsList(page, rowsPerPage, sortField, sortOrder, searchText, integrationId)
            .then((res) => {
                dispatch({
                    type: actionTypes.GET_CUSTOMERS_DATA_AND_LOADING,
                    data: res.data,
                    page: page,
                    rowsPerPage: rowsPerPage,
                })
            })
            .catch((e) => {
                console.error(e.response.data)
                dispatch({
                    type: actionTypes.GET_CUSTOMERS_DATA_AND_LOADING,
                    data: [],
                    page: page,
                    rowsPerPage: rowsPerPage,
                })
            })
            .finally(() => {
                dispatch({type: actionTypes.GET_CUSTOMERS_LOADING, value: false})
            })
    }
}

export const setSelectedCompany = (newCompany) => {
    return (dispatch) =>
        dispatch({
            type: actionTypes.SET_SELECTED_COMPANY,
            payload: newCompany
        });
}

export const getIsUserMasterFather = () => {
    return (dispatch) => {
        dispatch({type: actionTypes.GET_IS_USER_MASTER_FATHER, key: 'loading', payload: true})
        CustomersApiClient.fetchIsMasterFather()
            .then((res) => {
                dispatch({
                    type: actionTypes.GET_IS_USER_MASTER_FATHER,
                    key: 'data',
                    payload: res.data,
                })
            })
            .catch((e) => {
                console.error(e.response.data)
            })
            .finally(() => {
                dispatch({type: actionTypes.GET_IS_USER_MASTER_FATHER, key: 'loading', payload: false})
            })
    }
}

export const getCompanyName = (integrationId) => {
    return (dispatch) => {
        dispatch({type: actionTypes.GET_COMPANY_NAME_LOADING, value: true})
        CustomersApiClient.fetchCompanyName(integrationId)
            .then((res) => {
                dispatch({
                    type: actionTypes.GET_COMPANY_NAME,
                    payload: res.data,
                })
            })
            .catch((e) => {
                console.error(e.response.data)
            })
            .finally(() => {
                dispatch({type: actionTypes.GET_COMPANY_NAME_LOADING, value: false})
            })
    }
}
