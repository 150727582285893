import * as actionTypes from "./actionTypes";
import {updateObject} from "../../util/updateObject";
import {SELECTED_CUSTOMER} from "../../util/constants/AppConstants";
import {initialCustomer} from "./helper";

const initialState = {
    selectedCustomer: sessionStorage.getItem(SELECTED_CUSTOMER) ? JSON.parse(sessionStorage.getItem(SELECTED_CUSTOMER)) : initialCustomer,
    customerNameLoading: false,
    customersList: {
        data: {
            customers: [],
            totalElements: 0
        },
        loading: false
    },
    masterFather: {
        data: false,
        loading: false
    }
}

const setCustomersListData = (state, action) => {
    const tmpObject = {
        ...state.customersList,
        data: action.data,
        rowsPerPage: action.rowsPerPage,
        page: action.page
    }
    return updateObject(state, {customersList: tmpObject})
}

const setCustomersListLoading = (state, action) => {
    return updateObject(state, {
        customersList: updateObject(state.customersList, {
            loading: action.value
        })
    })
}

const setSelectedCompany = (state, action) => {
    sessionStorage.setItem(SELECTED_CUSTOMER, JSON.stringify(action.payload))
    return updateObject(state, {
        selectedCustomer: action.payload
    })
}

const setMasterFather = (state, action) => {
    return updateObject(state, {
        masterFather: updateObject(state.masterFather, {
            [action.key]: action.payload
        })
    })
}

const setCompanyName = (state, action) => {
    const tempObject = {
        ...state.selectedCustomer,
        name: action.payload
    }
    sessionStorage.setItem(SELECTED_CUSTOMER, JSON.stringify(tempObject))
    return updateObject(state, {selectedCustomer: tempObject})
}

const setCompanyNameLoading = (state, action) => {
    return updateObject(state, {
        customerNameLoading: action.value
    })
}

const customersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CUSTOMERS_DATA_AND_LOADING:
            return setCustomersListData(state, action);
        case actionTypes.GET_CUSTOMERS_LOADING:
            return setCustomersListLoading(state, action);
        case actionTypes.SET_SELECTED_COMPANY:
            return setSelectedCompany(state, action);
        case actionTypes.GET_IS_USER_MASTER_FATHER:
            return setMasterFather(state, action)
        case actionTypes.GET_COMPANY_NAME:
            return setCompanyName(state, action)
        case actionTypes.GET_COMPANY_NAME_LOADING:
            return setCompanyNameLoading(state, action)
        default:
            return state;
    }
}

export default customersReducer;
