import {Radio, TableCell, TableRow} from "@mui/material";
import EmptyRow from "../../../../ui/tables/table-rows/empty-row/EmptyRow";
import TableWithLabels from "../../../../ui/tables/table-with-labels/TableWithLabels";
import {useSelector} from "react-redux";
import {headCells} from "./CustomersTableHelper";
import PropTypes from "prop-types";

const CustomersTable = (props) => {
    const companies = useSelector(state => state.customersReducer.customersList.data.customers);
    const count = useSelector(state => state.customersReducer.customersList.data.totalElements);
    const loading = useSelector(state => state.customersReducer.customersList.loading);

    const selectNewCustomer = (row) => {
        props.setSelectedCustomer(row)
    }

    const tableRows = (
        count && count !== 0 ? companies.map((row, index) => {
            return (
                <TableRow key={`customer-table-row-${index}`} hover onClick={() => selectNewCustomer(row)}>
                    <TableCell align="left" style={{padding: "0 0 0 0.75em"}}>
                        <Radio
                            checked={props.selectedCustomer.integrationId === row.integrationId}
                            onChange={() => selectNewCustomer(row)}
                            value={row.integrationId}
                            name="selectedCustomer"/>
                    </TableCell>
                    <TableCell align="left">{row.integrationId}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.pIva}</TableCell>
                    <TableCell align="left">{row.codiceFiscale}</TableCell>
                </TableRow>
            );
        }) : <EmptyRow text="dato" colSpan={headCells.length}/>
    );

    return <TableWithLabels headCells={headCells}
                            tableRows={tableRows}
                            tableData={companies}
                            page={props.page}
                            fetchData={props.fetchData}
                            rowsPerPage={props.rowsPerPage}
                            count={count}
                            loading={loading}/>
}

CustomersTable.propTypes = {
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    selectedCustomer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        integrationId: PropTypes.string.isRequired,
        pIva: PropTypes.string,
        codiceFiscale: PropTypes.string
    }).isRequired,
    setSelectedCustomer: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired
}

export default CustomersTable;
