import KeycloakService from "../../service/KeycloakService";
import useAppRoutes from "./useAppRoutes";
import {Navigate, Route, Routes} from "react-router-dom";
import RouteWrapper from "./route-wrapper/RouteWrapper";
import {routePaths} from "../../util/constants/AppConstants";
import "../../asset/toastify/Toastify.css"
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import * as actions from "../../store/customers/actions";
import {RESOURCE_ACCESS_PORTALE_GA, ROLE_OPERATOR} from "../../util/constants/KeycloakConstants";

const App = () => {
    const [routes] = useAppRoutes()
    const dispatch = useDispatch()
    const isOperator = KeycloakService.hasResourceRole([ROLE_OPERATOR], RESOURCE_ACCESS_PORTALE_GA)

    useEffect(() => {
        console.log(KeycloakService.getToken())
        console.log(KeycloakService.getInstance.tokenParsed)
        if(!isOperator) {
            const tokenParsed = KeycloakService.getInstance.tokenParsed;
            const newCompany = {
                codiceFiscale: "",
                customerCode: tokenParsed.customerCode,
                integrationId: tokenParsed.companyId,
                name: tokenParsed.companyId,
                pIva: ""
            }
            dispatch(actions.getCompanyName(tokenParsed.companyId))
            dispatch(actions.setSelectedCompany(newCompany));
        }
        const username = KeycloakService.getUsername()
        if (username) {
            dispatch(actions.getIsUserMasterFather())
        }
    }, [dispatch, isOperator])

    return (
        <Routes>
            {routes.map(r => (
                <Route
                    path={r.path}
                    key={r.path}
                    element={
                        <RouteWrapper component={r.component}
                                      layout={r.layout}
                                      canSeeRoute={r.canSeeRoute}
                                      redirectTo={r.redirectTo}
                        />
                    }
                />
            ))}
            <Route
                path="*"
                element={<Navigate to={routePaths.HOME}/>}
            />
        </Routes>
    );
}

export default App;
