import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import {Collapse, ListItemButton, ListItemIcon, Typography, useTheme} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useState} from "react";

const ExpandableDrawerListItem = (props) => {
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(false)

    const expandIconStyle = {
        marginLeft: "auto",
        color: theme.palette.common.white
    }

    const handleExpandMenuItem = () => {
        setIsExpanded((oldIsExpanded) => !oldIsExpanded)
    }

    return (
        <>
            <ListItemButton
                onClick={handleExpandMenuItem}
                sx={{
                    padding: "0"
                }}>
                <NavLink exact
                         activeStyle={{backgroundColor: "#b14536"}}
                         to={props.to}
                         style={{
                             width: "100%",
                             display: "inline-flex",
                             padding: "16px",
                             textDecoration: "none"
                         }}>
                    <ListItemIcon sx={{color: theme.palette.common.white}}>
                        {props.icon}
                    </ListItemIcon>
                    <Typography variant="button" sx={{color: theme.palette.common.white}}>
                        {props.text}
                    </Typography>
                    {isExpanded ?
                        <ExpandLess sx={expandIconStyle}/> :
                        <ExpandMore sx={expandIconStyle}/>}
                </NavLink>
            </ListItemButton>
            <Collapse sx={{backgroundColor: "rgba(177, 69, 54, 0.5)"}} in={false} timeout="auto" unmountOnExit>
                {props.children}
            </Collapse>
        </>
    )
}

ExpandableDrawerListItem.propTypes = {
    to: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    text: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired
}

export default ExpandableDrawerListItem
