import {colors} from "./colors";
import {itIT} from "@mui/material/locale"
import {createTheme} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

export const theme = createTheme({
    palette: {
        ...colors
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    border: "solid 1px transparent",
                    borderRadius: 4,
                },
                contained: {
                    borderRadius: 20,
                    color: "#fff",
                    ":disabled": {
                        color: "#fff",
                        backgroundColor: "#ffeaa3",
                    },
                },
                outlined: {
                    borderRadius: 20,
                    border: `solid 1px ${colors.primary.dark} !important`,
                    ":disabled": {
                        border: `solid 1px ${colors.grey.light} !important`,
                    },
                    ":active": {
                        border: "none"
                    }
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    zIndex: zIndex.tooltip + 1
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                subtitle1: {
                    fontWeight: 500,
                },
                h5: {
                    fontWeight: 500,
                },
                body1: {
                    lineHeight: 1.75,
                    letterSpacing: "0.0275em"
                },
                body2: {
                    lineHeight: 1.43,
                    letterSpacing: "0.01562em"
                },
                caption: {
                    lineHeight: 1.33,
                    letterSpacing: "0.025em",
                    color: colors.font.light
                },
                overline: {
                    letterSpacing: "0.09375em",
                    fontSize: "0.625rem",
                    lineHeight: 1.6
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '1em 0 1em 2em'
                },
                head: {
                    backgroundColor: `${colors.grey.light} !important`
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: colors.grey.dark,
                    color: "#fff",
                    lineHeight: 1.43,
                    padding: "20px !important",
                    fontSize: "0.875rem",
                    letterSpacing: "0.01562em"
                },
                arrow: {
                    color: colors.grey.dark
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    '&.Mui-selected': {
                        color: colors.font.dark,
                    }
                }
            }
        }
    },
    spacing: [4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
}, itIT)
