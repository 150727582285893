import {lazy, useMemo} from "react";
import DefaultLayout from "../../layout/default/DefaultLayout";
import {routePaths} from "../../util/constants/AppConstants";
import KeycloakService, {enableAuth} from "../../service/KeycloakService";
import ContainerLayout from "../../layout/container-layout/ContainerLayout";
import {RESOURCE_ACCESS_TIPOLOGIA_SEDE, ROLE_TIPOLOGIA_SEDE} from "../../util/constants/KeycloakConstants";

const TipologiaSedi = lazy(() => import("../tipologia-sedi/TipologiaSedi"))
const NoAccess = lazy(() => import("../no-access/NoAccess"))

const useAppRoutes = () => {
    const hasTipologiaSedeRole = KeycloakService.hasResourceRole([ROLE_TIPOLOGIA_SEDE], RESOURCE_ACCESS_TIPOLOGIA_SEDE);

    const routes = useMemo(() => {
        return [
            {
                path: routePaths.HOME,
                component: TipologiaSedi,
                layout: DefaultLayout,
                canSeeRoute: hasTipologiaSedeRole || !enableAuth,
                redirectTo: routePaths.NOT_PERMITTED
            },
            {
                path: routePaths.NOT_PERMITTED,
                component: NoAccess,
                layout: ContainerLayout,
                canSeeRoute: true,
                redirectTo: routePaths.NOT_PERMITTED
            }
        ]
    }, [hasTipologiaSedeRole])

    return [routes]
}

export default useAppRoutes;
