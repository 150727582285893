import {initialLocationsListState} from "./helper";
import {updateObject} from "../../util/updateObject";
import * as actionTypes from "./actionTypes";

const initialState = {
    locationsList: initialLocationsListState,
    exportLoading: false,
    importLoading: false
}

const setLocationsListDataAndLoading = (state, action) => {
    return updateObject(state, {
        locationsList: updateObject(state.locationsList,
            {[action.key]: action.value})
    })
}

const setActionLoading = (state, action) => {
    return updateObject(state, {
        exportLoading: action.value
    })
}

const setImportActionLoading = (state, action) => {
    return updateObject(state, {
        importLoading: action.value
    })
}

const locationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LOCATIONS_LIST_DATA_AND_LOADING:
            return setLocationsListDataAndLoading(state, action)
        case actionTypes.EXPORT_LOCATION_LIST_LOADING:
            return setActionLoading(state, action)
        case actionTypes.IMPORT_LOCATIONS_FILE_LOADING:
            return setImportActionLoading(state, action)
        default:
            return state;
    }
}

export default locationsReducer;
