import * as actionTypes from "./actionTypes";
import {updateObject} from "../../util/updateObject";

const initialState = {
    associatedTags: {
        data: {
            tags: [],
            firstRenderTasks: []
        },
        loading: false,
        syncBtn: {
            buttonLoading: false,
            error: false
        }

    }
}

const setAssociatedTagsAndLoading = (state, action) => {
    return updateObject(state, {
            associatedTags:
                updateObject(state.associatedTags, {
                    [action.key]: action.value
                })
        }
    )
}

const createNewTag = (state, action) => {
    return updateObject(state, {
        associatedTags:
            updateObject(state.associatedTags, {
                data:
                    updateObject(state.data, {
                        tags: [...state.associatedTags.data.tags, action.tag],
                        firstRenderTasks: [...state.associatedTags.data.firstRenderTasks]
                    })
            })
    })
}

const deleteTag = (state, action) => {

    const locationTags = state.associatedTags.data.tags.filter(tag => tag.label !== action.tagName)
    const firstRenderTags = state.associatedTags.data.firstRenderTasks.filter(tag => tag.label !== action.tagName)

    return updateObject(state, {
        associatedTags:
            updateObject(state.associatedTags, {
                data:
                    updateObject(state.data, {
                        tags: locationTags,
                        firstRenderTasks: firstRenderTags
                    })
            })
    })
}

const updateAssociatedTags = (state, action) => {
    return updateObject(state, {
        associatedTags: updateObject(state.associatedTags, {
            syncBtn: updateObject(state.syncBtn, {
                [action.key]: action.value
            })
        })
    })
}

const TagManagerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSOCIATED_TAGS_AND_LOADING:
            return setAssociatedTagsAndLoading(state, action)
        case actionTypes.CREATE_NEW_TAG:
            return createNewTag(state, action)
        case actionTypes.DELETE_LOCATION_TAG:
            return deleteTag(state, action)
        case actionTypes.UPDATE_ASSOCIATED_TAGS_AND_LOADING:
            return updateAssociatedTags(state, action)
        default:
            return state;
    }
}

export default TagManagerReducer;
