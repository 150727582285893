import Navigation from "../../component/navigation/Navigation";
import React from "react";

const DefaultLayout = ({children}) => {

    return (
        <Navigation>
            {children}
        </Navigation>
    )
}

export default DefaultLayout;
