import fastWebLogo from '../../../../asset/images/nav-header.png';
import DrawerListItem from "./drawer-list-item/DrawerListItem";
import ExpandableDrawerListItem from "./expandable-drawer-list-item/ExpandableDrawerListItem";
import {Box, List, ListSubheader} from "@mui/material";

const DrawerList = () => {
    return (
        <List
            component="nav"
            subheader={
                <ListSubheader sx={{
                    padding: '0',
                    height: "81px"
                }} component="div">
                    <Box component='img' sx={{
                        height: "100%",
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
                    }} src={fastWebLogo} alt='FastWeb Logo'/>
                </ListSubheader>
            }
            sx={{
                width: '100%',
                maxWidth: 360,
                backgroundColor: "transparent",
            }}
        >
            {[].map((li, idx) => (
                li.subItems ?
                    <ExpandableDrawerListItem key={"expandable-drawer-list-item-" + idx} to={li.to} text={li.text}
                                              icon={li.icon}>
                        {li.subItems.map((si, idx2) => (<DrawerListItem key={"drawer-list-item-" + idx2}
                                                                        text={si.text}
                                                                        fontStyle="caption"
                                                                        onClick={() => {
                                                                        }}
                                                                        icon={si.icon}
                                                                        to={si.to}/>
                        ))}
                    </ExpandableDrawerListItem> : <DrawerListItem key={"drawer-list-item-root-" + idx}
                                                                  {...li}
                                                                  onClick={() => {
                                                                  }}
                                                                  fontStyle="button"/>
            ))}
        </List>
    );
}

export default DrawerList;
