import PropTypes from "prop-types";
import {TableCell, TableRow, Typography} from "@mui/material";

const EmptyRow = (props) => {
    return (
        <TableRow>
            <TableCell colSpan={props.colSpan} sx={{
                textAlign: 'center',
                padding: "1em 0"
            }}>
                <Typography variant="body2">
                    Nessun {props.text}
                </Typography>
            </TableCell>
        </TableRow>
    )
}

EmptyRow.propTypes = {
    colSpan: PropTypes.number,
    text: PropTypes.string
}

export default EmptyRow;
